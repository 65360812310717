<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list='columns.map(item=>{return item.dataIndex})'
            ref="list"
			:is_page="false"
			rowKey="id"
            :submit_preprocessing="submit_preprocessing"
			@expandedRowsChange="expandedRowsChange($event)"
            @list_after="list_after"
        >
			<template :slot="item.dataIndex" slot-scope="data" v-for="(item,index) in columns">
			    <a href="#" @click="toCustomerIndex(data,item)">{{data.record[item.dataIndex]}}</a>
			</template>
			
        </TableList>

    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { customerReport ,customerReportSon,getLevel} from "@/api/customer";

const columns = [
];

export default {
    name: "IndexLevel",
    components: {
		TableList,
    },
    data() {
        return {
            get_table_list: customerReport,
            submit_preprocessing: {
                array_to_string: ['department','role_id']
            },
            order_details: {
                goods: [],
            },
            columns,
            form_data_seo: {
                list: [
					{
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					{
                        type: "tree-select",
                        name: "role_id",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role_id",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
                ],
                ...this.$config.form_data_seo
            },
            form_data: {},
        };
    },
    async created() {
        this.$method.get_department().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list,'department','treeData',res)
		});
		
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role_id") {
        //             item.list = [...this.$config.status_all, ...res];
        //         }
        //     });
		// });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role_id") {
					item.treeData = res;
				}
			});
		});
		this.get_type()
    },
    methods: {
		toCustomerIndex(data,item){
			this.$router.push({
			    path: '/customer/index',
			    query: {
			        [data.record.type == 1 ? 'uid' : 'department_id']:data.record.id,
					level_id:item.dataIndex
			    }
			}).catch(err => { 
				this.$router.push({
				    path: '/customer/index',
				    query: {
				        [data.record.type == 1 ? 'uid' : 'department_id']:data.record.id,
						level_id:item.dataIndex
				    }
				})
			})
		},
		get_type(){
			getLevel().then(res=>{
				let columns = [
					{
						title: "名称",
						dataIndex: "name",
					},
					{
						title: "总数",
						dataIndex: "customer_total",
					},
				];
				res.data.list.map(item => {
					let obj = {
						title:item.name + '级',
						dataIndex:item.id,
						scopedSlots: {
						    customRender: item.id
						}
					};
					columns.push(obj);
				})

				this.columns = columns
			})
		},
		see({record},type){
			
			this.$router.push({
				path:"/imitation/index",
				query:{
					[record.type == 2 ? 'department' : 'uid']:record.id,
					data_type:type,
					visit_date:record.visit_time,
					...this.$refs.list.seo_data,
				}
			});
		},
		get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
        },
		expandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    customerReportSon({
                        data: {
                            id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            ...res.data.user.map((vo) => {
                                
                                return vo;
                            }),
                            ...res.data.list.map((vo) => {
                                vo.children = [];
                                
                                return vo;
                            })

                        ];
                        item.is_req = true;

                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },
        list_after(res) {
            // this.get_details();
            let list = this.$refs.list.list;
            list = list.map(item => {
                item.children = [];
                
                return item;
            });

        },
    }
};
</script>

<style lang="less">

</style>